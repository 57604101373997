export const mapSkuToName = (productSku: string): string => {
    switch (productSku) {
        case 'rio-sku00000058':
            return 'Test Product';
        case 'rio-sku00000047':
            return 'Geo';
        case 'rio-sku00000027':
            return 'Timed';
        case 'man-sku00000004':
            return 'Compliant M';
        case 'man-sku00000066':
            return 'MAN SimplePay';
        case 'man-sku00000081':
            return 'MAN SimplePay (Test)';
        case 'man-sku00000085':
            return 'MAN SmartRoute';
        default:
            return 'no product defined';
    }
};
