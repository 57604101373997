import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { accessToken } from '../../../configuration/tokenHandling/accessToken';
import { mapApiBookables } from './bookable/bookableMapper';
import { FreeTrialMappedResponse } from '../freeTrial/redux/types';
import { mapApiFreeTrialStatus } from './freeTrial/freeTrialMapper';
import { Bookable } from '../bookable/redux/types';

export const marketplaceApi = createApi({
    reducerPath: 'marketplaceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.MARKETPLACE_SERVICE,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${accessToken.getAccessToken()}`);
            return headers;
        },
    }),
    tagTypes: ['FreeTrial', 'Bookables'],
    endpoints: (builder) => ({
        getFreeTrialStatus: builder.query<FreeTrialMappedResponse, void>({
            query: () => ({
                url: '/free-trial',
            }),
            transformResponse: mapApiFreeTrialStatus,
            providesTags: ['FreeTrial'],
        }),
        getBookables: builder.query<Bookable, { sku: string; level: string; resourceId: string | undefined }>({
            query: ({ sku, level, resourceId }) => ({
                url: `/activations/${sku}/bookable`,
                params: { level, resource_ids: resourceId },
            }),
            transformResponse: (apiBookables: unknown, meta, { resourceId }): Bookable => {
                return mapApiBookables(apiBookables, resourceId);
            },
            providesTags: ['Bookables'],
        }),
    }),
});

export const { useGetFreeTrialStatusQuery, useLazyGetBookablesQuery } = marketplaceApi;
